label,
input,
textarea {
    display: block;
}

.form {
    .input {
        border-radius: 5px;
        border: 1px solid #E3E3E3;
    }

    textarea {
        width: 79% !important;
    }
}

.hidden {
    display: none;
}

.exhibition-category {
    max-width: 400px !important;
    height: 5rem !important;

    .categories {
        display: flex;
        justify-content: space-between;

        .category {
            display: flex;
            flex-direction: column;

            .category-icon {
                width: 32px;
                height: 32px;
                margin: auto;

                &.selected {
                    border: 2px solid #F79839;
                }
            }
        }


    }
}

.select {
    position: relative;
    width: 60px;

    .dropdown {
        border: 1px solid #E3E3E3;
        border-radius: 5px;
        width: 80px;
        display: flex;
        justify-content: space-between;
    }

    .dropdown-icon {
        background-color: #3D1412;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
            width: 25px;
        }
    }

    .dropdown.active {
        position: absolute;
        background-color: white;
    }

    .language-icon {
        width: 20px;
        height: 20px;
        border: none;
    }

    .languages {
        margin: 0;
        width: 100%;
    }

    .language {
        border-bottom: 1px solid #D0D0D0;
        padding: 0.5rem 0;
        cursor: pointer;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

}

.download-button {
    display: inline-block;

    .qr-code {
        display: none;
    }

    .button {
        background-color: #fff;
    }
}

.single-view {

    p {
        margin-top: 0;
    }

    .detail {
        width: 80%;
        height: 4rem;
        margin-bottom: 1rem;
    }

    .description {
        width: 80%;
        margin-top: 4rem;
    }

    .general-information {

        .detail:first-of-type {

            p,
            label {
                margin-top: 0;
            }
        }

        .description {

            .options-detail {
                p:first-child {
                    font-weight: bold !important;
                }
            }
        }

    }

    .additional-information {
        .image {
            width: 80%;
        }
    }


    .detail p:first-of-type,
    label {
        margin-bottom: 0.5rem;
        margin-top: 0;
        font-weight: bold;
    }

    .detail p:nth-of-type(2),
    input {
        margin-bottom: 1rem;
    }

    .information {
        margin-left: 10px;
        margin-top: 0;
        display: inline-block;
    }

    .options-detail {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            font-weight: normal !important;
        }
    }

    .image {
        height: 200px;
        background-color: #f1f1f1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;

        img {
            max-height: 100%;
            max-width: 100%;

        }
    }

    .upload {

        position: relative;

        .image.empty {
            border: 2px dashed #bababa;
            background-color: transparent;
            border-radius: 5px;
        }

        label {
            position: absolute;
            bottom: 1px;
            right: 120px;
            color: #505050;
            border: 2px solid #D0D0D0;
            border-radius: 10px;
            background-color: white;
            padding: 0.2rem 1rem;
            font-weight: normal;
        }

        input {
            display: none;
        }

    }



    textarea,
    input {
        font-family: "Open Sans";
    }


}

.single-view.artwork-form {
    width: 100%;

    .description-artwork {
        grid-column: 1 / 3;

        .button {
            margin-top: 3rem;
            background-color: #fff;
        }

        .translations-list {
            display: grid;
            grid-template-columns: repeat(2, 47.5%);
            grid-gap: 5%;

            .translation:first-of-type .options-detail {
                justify-content: space-between;
            }

            .options-detail {
                justify-content: flex-end;
                margin-bottom: 0.25rem;
            }

            .icon {
                display: flex;
                border: 1px solid #D0D0D0;
                border-radius: 18px;
                padding: 0.2rem;
                width: 45px;
                justify-content: space-around;

                img {
                    width: 20px;
                    height: 20px;
                }

                p {
                    margin: 0;
                }
            }
        }
    }
}

.form {
    .options-detail {
        width: 80%;

        .input {
            width: 40%;
        }
    }

    .duration {
        display: inline-block;

        .options-detail {

            p {
                margin: 0 10px !important;
                margin-bottom: 1rem !important;
            }
        }

        .input {
            width: 100%;
        }

    }
}