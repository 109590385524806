body {
  margin: 0;
  font-family: "Open Sans", Comfortaa, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "source-code-pro", Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import "./scss/features/Create.scss";
@import "./scss/sensors/ListSensor.scss";
@import "./scss/features/List.scss";
@import "./scss/Home.scss";
@import "./scss/NavBar.scss";
@import "./scss/LandingPage/LandingPage.scss";
@import "./scss/features/FilterHeader.scss";
@import "./scss/LandingPage/BurgerMenu.scss";
@import "./scss/LandingPage/LogIn.scss";

// =====================================
// =            Variables              =
// =====================================
* {
  font-size: 1rem;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.125rem;
  margin: 0;
}