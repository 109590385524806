// Contains the section title eg. "NFC Sensors" and the filters
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #000;
  margin-bottom: 1.5rem;

  h1 {
    margin-bottom: 0;
  }

  // Styling for the filters depending on whether they're selected or not
  // Selected filters are colored black, while unselected filters are colored grey
  div {
    span {
      cursor: pointer;

      &.active {
        color: black;
      }
    }
  }
}

// The container that displays all the sensors
#sensors {
  display: flex;
  flex-wrap: wrap;

  .sensor {
    display: flex;
    flex-direction: column; // For the content (Sensor ID, Artwork name, Exhibition name) inside a sensor
    margin: 0.5rem;
    padding: 1rem;
    border: 1px solid lightgrey;
    box-sizing: border-box;
    flex-basis: calc(33.33% - 1rem); // 3 sensors in every row

    .btn-wrapper {
      display: flex;
      justify-content: flex-end; // Push the button to the right of the container

      // Basic styles for the button to make it look decent for the time being
      button {
        padding: 0.5rem;
        color: white;
        background: coral;
        border-width: 0;
        font-weight: bold;
        border-radius: 0.75rem;
      }
    }
  }
}