.home {

    a {
        color: black;
    }

    * {
        box-sizing: border-box;
    }

    background: transparent linear-gradient(180deg, #FFDBBF33 0%, #FFA8BF33 100%) 0% 0% no-repeat padding-box;

    h1,
    h3,
    label {
        color: #88163B;
    }

    h2,
    p {
        color: #276180;
    }

    h2 {
        font-size: 1.5rem;
        text-transform: uppercase;
        margin-bottom: 2rem;
        font-weight: bold;
    }

    .mobile {
        display: block;
    }

    .desktop {
        display: none !important;
    }

    @media screen and (min-width:1000px) {
        .mobile {
            display: none;
        }

        .desktop {
            display: flex !important;
        }
    }

    .red-text {
        color: #88163B;
    }

    .download-buttons {
        width: 300px;
        margin: auto;
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
    }

    button {
        border: 1.5px solid #F79839;
        border-radius: 21px;
        width: 200px;
        background: #FFF url("/assets/btn.png") center / 200px no-repeat;
        background-position: -2px -3px;
        padding: 0.5rem 0;
        color: #88163B;
        cursor: pointer;

        a {
            display: block;
        }
    }

    .home-view {
        text-align: center;
        padding: 4.5rem 2.5rem 3rem 2.5rem;

        @media screen and (min-width:1000px) {
            height: 95vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 5rem;
        }
    }

    .home-nav-bar.mobile {
        background-color: white;
        padding: 0.5rem 1rem;

        .navigation {
            width: 50%;
            justify-content: flex-end;
        }

        .icon-button {
            width: 40px;
            height: 40px;
            border: 2px solid #F79839;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 1rem;

            img {
                width: 30px;
            }
        }
    }

    .home-nav-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        width: 100%;
        padding: 1rem 2rem;
        background-color: #fff;
        z-index: 3;

        transition: all 0.2s ease-in-out;

        .home-button {
            width: 60px;
        }

        a {
            text-decoration: none;
            color: #88163B;
            cursor: pointer;
        }

        .navigation {
            width: 400px;
            display: flex;
            justify-content: space-between;
            opacity: 1;

            transition: all 0.2s ease-in-out;
        }
    }

    .home-nav-bar.simplified {
        background-color: #FFF8F2;
        border-bottom: none;

        .navigation {
            opacity: 0;
        }
    }

    .home-footer {
        background-color: #276180;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.5rem 2rem;

        .home-button {
            width: 45px;
        }

        a {
            text-decoration: none;
            color: white;
            font-size: 0.75rem;
        }

        .navigation {
            width: 50%;
            display: flex;
            justify-content: space-between;

            @media screen and(min-width: 1000px) {
                width: 20%;
            }
        }
    }

    .hero {
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        padding-top: 8.5rem;

        @media screen and (min-width:1000px) {
            flex-direction: row;
            padding: 5rem;

        }

        .welcome-message {

            @media screen and (min-width:1000px) {
                width: 50%;
            }

            img {
                width: 300px;
            }
        }

        .app-description {

            .description {
                padding-bottom: 2rem;
                border-bottom: 1.5px solid #F79839;
            }


            @media screen and (min-width:1000px) {
                width: 50%;
            }
        }
    }

    #features {
        padding-top: 6.7rem;

        h3 {
            font-weight: normal;
        }

        text-align: center;
        background-color: #fff;

        img {
            width: 80%;
        }


        .container {
            width: 70px;
            height: 70px;
            border: 5px solid #F79839;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;

            img {
                width: 60px;
                height: 60px;
                padding: 0.3rem;
            }
        }
    }

    .team-members {
        position: relative;
        overflow: hidden;

        @media screen and(min-width:1000px) {
            height: 100%;
            padding: 6rem !important;
        }

        .top-border {
            position: absolute;
            top: 0;
            width: 200%;
            left: 0;

            @media screen and(min-width: 1000px) {
                width: auto;
            }
        }

        .bottom-border {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .members {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (min-width:1000px) {
            height: 450px;
            flex-wrap: wrap;
        }


        .team-member-card {
            background-color: #fff;
            width: 290px;
            height: 112px;
            display: flex;
            border-radius: 500px;
            align-items: center;
            margin: 1rem 0;
        }

        p {
            margin: 0;
            font-size: 0.75rem;
        }

        .container {
            width: 80px;
            height: 80px;
            border: 2px solid #F79839;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 1rem;
            overflow: hidden;

            img {
                width: 75px;
            }
        }

        .content {
            width: 60%;

            .name {
                font-weight: bold;
                font-size: 0.875rem;
            }
        }

        .social-icons {
            width: 70px;
            margin: auto;
            margin-top: 0.2rem;
            display: flex;
            justify-content: space-evenly;
        }
    }

    #contact {
        background-color: #fff;

        form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: left;

            label {
                margin-left: 1rem;

            }

            input,
            textarea {
                border: 1.5px solid #F79839;
                border-radius: 21px;
                padding: 0.5rem 1rem;
                width: 280px;
                margin-top: 0.2rem;
                margin-bottom: 0.5rem;
                background-color: #ffa8bf1a;
            }

            textarea {
                font-family: "Open Sans", Comfortaa, Arial, sans-serif;
            }

            #message {
                height: 8rem;
            }

            .museum {
                margin-left: 1rem;

                label {
                    display: block;
                    position: relative;
                    cursor: pointer;
                    user-select: none;
                    margin: 0;
                    padding-left: 25px;
                }


                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                    margin: 0;
                }

                .check {
                    position: absolute;
                    top: 4px;
                    left: 0;
                    height: 15px;
                    width: 15px;
                    border: 1px solid #F79839;
                    border-radius: 50%;
                }

                input:checked~.check {
                    background-color: #F79839;
                }
            }

            button {
                margin-top: 2rem;
            }
        }


    }

    #download {
        padding-right: 0;
        padding-left: 0;
        padding-bottom: 0;

        @media screen and(min-width: 1000px) {
            display: flex;
            flex-direction: row-reverse;
            padding: 0;

            .download-reference,
            .download-message {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 50%;
            }
        }


        .download-reference {
            padding-top: 4rem;
            padding-bottom: 2rem;
            background-color: #fff;

            @media screen and(min-width:1000px) {
                padding-bottom: 0;
            }

            .desktop-image {
                width: 300px;

                @media screen and(min-width:1000px) {
                    width: 400px;
                }

            }
        }

        .download-message {
            padding: 0 2rem;

            @media screen and(min-width:1000px) {
                padding: 0;
            }

            img {
                width: 250px;
            }

            p {
                font-weight: bold;
                font-size: 1.5rem;
            }
        }
    }
}