.filters {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;

    .filter-title {
        background-color: #FFEDDF;
        padding: 1rem 3rem;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 15%;
        text-align: center;
        text-transform: capitalize;
        cursor: pointer;
        position: relative;


        &.selected {
            background-color: white;
            border: 2px solid #FFDBBF;
            border-bottom: 2px solid white;
            bottom: -3px;
        }
    }
}

.section-header-exhibition {
    display: block;

    .filters {
        justify-content: space-between;

        .prioritized-exhibitions {
            display: flex;
            width: 50%;
            justify-content: space-between;

            .filter-title {
                width: 30%;
            }
        }
    }
}