.app-dashboard {
    display: grid;
    grid-template-columns: 110px auto;
    grid-template-rows: 90px auto;
    background: transparent linear-gradient(180deg, #FFF8F2 0%, #FFEEF2 100%) 0% 0% no-repeat padding-box;
    ;
    height: 100vh;

    .logo {
        img {
            width: 70px;
        }
    }

    .view {
        overflow-y: auto;
        padding-right: 10%;
        padding-left: 5%;
        padding-top: 2%;
        padding-bottom: 100px;

        background-color: white;
        border-top-left-radius: 30px;

        a,
        a:focus,
        a:active {
            color: #333333;
        }

        h2 {
            margin-top: 0;
        }

        .overview-list {
            display: flex;
            justify-content: space-between;

            .overview-item {
                border: 1px solid grey;
                width: 25%;
                text-align: center;
                padding: 5px 25px;

                .header {
                    font-weight: bold;
                }

                .number {
                    font-size: 1.5rem;
                    font-weight: bold;
                }



                .exhibition-overview,
                .artwork-overview,
                .sensor-overview {
                    text-align: left;
                    margin-bottom: 1rem;

                    p {
                        margin: 0;
                    }
                }
            }
        }
    }
}

.caption {
    font-size: 0.75rem;
}