.artwork-item,
.exhibition-item {
  margin: 1rem auto;
  display: flex;
  justify-content: space-between;
  padding: 2rem;

  .information {
    width: 60%;
  }

  .icons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .icons-artwork {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  h2,
  h3,
  p {
    color: #000;
  }
}

.list {
  position: relative;

  .list-view {
    border: 2px solid #FFDBBF;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    .list-item {

      .image {
        width: 200px;
        height: 157px;
        background-color: #f1f1f1;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .information {
        margin: 0 1rem;
      }
    }

    .spacer {
      border-bottom: 1.5px solid #bababa;
      width: 95%;
      margin: auto;
    }

    .list-item:last-of-type {
      .spacer {
        display: none;
      }
    }
  }
}

.button {
  border: 2px solid #F79839;
  border-radius: 10px;
  padding: 0.25rem 1.3rem;
  box-sizing: border-box;
  text-decoration: none;
}

.button:active,
.button:hover {
  background-color: #F79839;
}

.list-artwork {

  span {
    font-weight: bold;
  }

  .artwork-item {
    border: 1.5px solid #D0D0D0;
    border-radius: 30px;

    .image {
      width: 200px;
      height: 157px;
      background-color: #f1f1f1;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .information {
      margin: 0 1rem;
    }
  }
}

.edit-icon {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-decoration: none;
  font-size: 12px;

  .icon-no-background {
    width: 30px;
  }
}

.add {
  position: fixed;
  bottom: 5%;
  right: 5%;
  text-decoration: none;
  font-size: 0.85rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  img {
    background-color: #3D1412;
    border-radius: 50%;
    width: 35px;
    display: block;
  }
}

.single-view {
  display: grid;
  grid-template-columns: repeat(2, 47.5%);
  column-gap: 5%;
}

.section-header-artworks {
  display: flex;
  justify-content: space-between;

  .search-bar {
    display: flex;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    input {
      border: none;
      border-bottom: 1px solid black;
    }
  }
}