.top-navigation,
.logo,
.main-navigation {

    a {
        text-decoration: none;
    }

    a,
    a:focus,
    a:active,
    a:visited {
        color: rgb(61, 20, 18);
    }
}

ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    text-align: center;

    a,
    li {
        margin-left: 25px;
        padding: 15px;
        border-top-left-radius: 40%;
        border-bottom-left-radius: 40%;
    }

    img {
        width: 25px;
        border-radius: 50%;
        border: 1px solid #3D1412;
        padding: 2px;
    }

    .selected {
        background-color: #fff;

        img {
            background-color: #F79839 !important;
            border: none;
        }
    }

    .selected-top {
        border-bottom-left-radius: 0;
        border-top-right-radius: 50%;
        background-color: #fff;

        img {
            background-color: #F79839;
            border: none;
        }
    }


}

.top-navigation {
    display: flex;
    justify-content: flex-end;

    .navigation {
        width: 25%;
        display: flex;
        justify-content: space-evenly;
        margin: 0;
        margin-top: 25px;
    }

    img {
        display: block;
        margin: auto;
    }
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-navigation {
    display: flex;

    .navigation {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        width: 100%;
    }

    .svg-inline--fa {
        display: block;
        margin: auto;
    }
}